import React from 'react';
import ProductItem from './ProductItem';

const ProductList = props => {
  if (!props.products || !Object.keys(props.products)) {
    return <div></div>;
  }

  return (
    <div className="flex flex-row flex-wrap justify-around">
      {props.products.map(product => {
        const productData = product.node ? product.node : product;
        // fix and trap error from missing integration images
        let imageResult = ``;
        try {
          imageResult = productData.images;
        } catch (e) {
          imageResult = { src: `/icons/icon-512x512.png` };
        }
        return (
          <ProductItem
            addToCart={props.addProductToCart}
            attributes={productData.attributes}
            categories={productData.categories}
            description={productData.description}
            id={productData.wordpress_id}
            image={imageResult}
            key={productData.id}
            name={productData.name}
            price={productData.price}
            regularPrice={productData.regular_price}
            short_description={productData.short_description}
            slug={productData.slug}
          />
        );
      })}
    </div>
  );
};

export default ProductList;
