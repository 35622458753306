import React, { useState } from 'react';
import InputNumber from './InputNumber';
import { AiOutlineShopping } from 'react-icons/ai';
import { FaRegCreditCard, FaQrcode } from 'react-icons/fa';
import { Link } from 'gatsby';
import { Carousel } from 'react-responsive-carousel';

const ProductItem = props => {
  const [qtd, setQtd] = useState(1);
  const changeQtd = newQtd => {
    setQtd(newQtd);
  };
  return (
    <div className="w-1/2 sm:w-64 relative rounded border hover:shadow-2xl overflow-hidden sm:m-4">
      {//discount flag
      props.price !== props.regularPrice && (
        <div className="p-2 mt-4 left-0 absolute rounded-r-lg text-base font-serif font-bold shadow bg-red-600 text-gray-100 z-40">
          {(
            ((props.regularPrice - props.price) / props.regularPrice) *
            100
          ).toFixed(0)}
          % off
        </div>
      )}
      <Carousel
        emulateTouch
        infiniteLoop
        showArrows={true}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        useKeyboardArrows
      >
        {props.image.map((node, index) => (
          <div key={index}>
            <Link key={props.id} to={`/produto/${props.slug}`}>
              <div>
                <img
                  alt={props.name}
                  className="object-cover w-full h-54"
                  key={index}
                  loading="lazy"
                  src={node.src.replace(`.jpg`, `-416x416.jpg`)}
                />
              </div>
            </Link>
          </div>
        ))}
      </Carousel>
      {/* <img
          alt={props.name}
          async="on"
          className="object-cover w-full h-40"
          loading="lazy"
          src={props.image.replace(`.jpg`,`-416x416.jpg`)}
          /> */}
      <div className="px-4 pt-3">
        <Link key={props.id} to={`/produto/${props.slug}`}>
          <div className="text-base font-bold text-gray-700 truncate-2-lines">
            {props.name}
          </div>

          <p
            className="text-xs py-1 truncate"
            dangerouslySetInnerHTML={{
              __html: props.description
                .slice(0, 200)
                .replace(/<\/?[^>]+(>|$)/g, ``)
            }}
          ></p>

          <div>
            <div className="text-gray-800 text-sm">
              {//discount flag
              props.price !== props.regularPrice && (
                <div>
                  de&nbsp;
                  <span className="stroke-current text-sm line-through text-gray-600">
                    R${` `}
                    {(props.regularPrice * 1).toLocaleString(`pt-BR`, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </span>
                  &nbsp;por&nbsp;
                </div>
              )}

              <div className="mr-1 text-xl text-gray-500 font-bold">
                R$&nbsp;
                <span className="text-3xl text-orange-500 font-bold">
                  {(props.price * 0.9).toLocaleString(`pt-BR`, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </span>
              </div>

              <div className="flex text-xs">
                <FaQrcode className="text-base w-5 mr-1 text-primary" />
                <div>
                  No pix{` `}
                  <span className="text-green-600">(10% off)</span>
                </div>
              </div>

              <div className="flex text-xs">
                <FaRegCreditCard className="w-5 mr-1 my-1 text-primary" />
                <div>
                  3x R${` `}
                  {(props.price / 3).toLocaleString(`pt-BR`, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                  {` `}
                  <span className="text-green-600">(sem juros)</span>
                </div>
              </div>
            </div>
          </div>

          {/*
            <div>
            <div className="text-gray-800 text-sm font-bold">
            { //discount flag
            props.price !== props.regularPrice &&
            <div>
            de&nbsp;
            <span className="stroke-current text-sm line-through text-gray-600">
            R$ {(props.regularPrice*1).toLocaleString('pt-BR', {minimumFractionDigits:2, maximumFractionDigits:2})}
            </span>
            &nbsp;por&nbsp;
            </div>
            }
            R$ {(props.price*1).toLocaleString('pt-BR', {minimumFractionDigits:2, maximumFractionDigits:2})}
            <span className="px-2 text-sm text-gray-500 font-bold">
            </span>
            <div className="text-gray-800 text-sm font-bold">
            R$ {(props.price*0.9).toLocaleString('pt-BR', {minimumFractionDigits:2, maximumFractionDigits:2})}
            <span className="px-2 text-red-600 text-sm font-bold">
            (10 % off boleto)
            </span>
            </div>
            </div>
            </div>
            */}
        </Link>
        <div>
          <div className="flex flex-col sm:flex-row items-center py-3">
            <InputNumber
              className="w-full sm:w-32 lg:w-16"
              onChange={changeQtd}
            />
            <button
              className="flex w-full px-4 py-2 sm:py-1 my-2 sm:mx-2 rounded shadow text-white bg-primary hover:text-black hover:bg-amarelo"
              onClick={() =>
                props.addToCart(props.id, {
                  qtd: qtd,
                  price: props.price,
                  img: props.image[0].src,
                  name: props.name
                })
              }
            >
              <AiOutlineShopping className="my-1 mr-2" />
              Comprar
            </button>
          </div>
        </div>

        {/* 
        <div>
          {props.categories.map((category, index) => (
            <span
              className="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-semibold text-gray-600 mr-2"
              key={index}
            >
              <Link
                className={`text-${category.slug}`}
                to={`/categoria/${category.slug}`}
              >
                {category.slug}
              </Link>
            </span>
          ))} */}
        {/*props.attributes.map((attribute, index) => (
              <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-semibold text-gray-600 mr-2" key={index}>
                <Link className={`text-${attribute.options}`} to={`/search?q=${attribute.options}`}>
                  #{attribute.options}
                </Link>
              </span>
            ))
        </div>
            */}
      </div>
    </div>
  );
};

export default ProductItem;
